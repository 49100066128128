.qrcode-container {
  margin-top: 100px;
  height: 185px;
  width: 151px;
  border: 1px solid #f4404f;
}
.qrcode-care {
  width: 150px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  background-color: #f4404f;
}

.qrcode-image {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .qrcode-container {
    margin-top: 30px;
  }
}
