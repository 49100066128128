.App {
  height: 100%;
}
.App-background {
  height: 100%;
}

#loading {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
