.video-js .vjs-marquee {
    /*
      默认不显示
    */
    display: none;
    color: #ffffff;
    opacity: 0.5;
    font-size: 16px;
    position: absolute;
    top: 999999px;
    left: 999999px;
    min-width: 80px;
    min-height: 40px;
    line-height: 40px;
    white-space: nowrap;
  }

  /* 
  在播放时候显示
  */
.video-js.vjs-paused.vjs-has-started .vjs-marquee,
.video-js.vjs-has-started .vjs-marquee{
  display: block;
}