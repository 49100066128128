.concat-title {
  margin-top: 100px;
  color: #bfc4ce !important;
}
.concat-hasCursor {
  cursor: pointer;
}
.concat-noneCursor {
  cursor: normal;
}
@media screen and (max-width: 767px) {
  .concat-title {
    margin-top: 40px;
  }
}
